import {Component, OnInit} from '@angular/core';
import {ConfigService} from './service/config.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  varTitre: string;
  varconnecter = false;
  constructor(private configService: ConfigService) { }

  ngOnInit() {
    this.varconnecter = this.configService.varconnecter;
  }

  // contraole l'affichage du menu
  varAffiche() {
    return 1;
  }

  // contraole l'affichage du menu Fin
  deconnexion() {
    this.configService.varconnecter = false;
  }
}
