import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HomeComponent } from './views/home/home.component';
import {ListeappelsComponent} from './views/listeappels/listeappels.component';
import {AppelComponent} from './views/listeappels/appel/appel.component';
import {DatePipe} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { UtilisateurComponent } from './views/lsteutilisateur/utilisateur/utilisateur.component';
import {LsteutilisateurComponent} from './views/lsteutilisateur/lsteutilisateur.component';
import { LoginComponent } from './views/login/login.component';
import { PremiereconxComponent } from './views/premiereconx/premiereconx.component';
import {AuthguardService} from './service/authguard.service';
import {ConfigService} from './service/config.service';
import {UtilisateurService} from './service/utilisateur.service';
import {AppelService} from './service/appel.service';
import {AjoutappelComponent} from './views/listeappels/ajoutappel/ajoutappel.component';
import { RelanceComponent } from './views/listeappels/relance/relance.component';
import { JamaisrelanceComponent } from './views/listeappels/jamaisrelance/jamaisrelance.component';
import { ImporterComponent } from './views/listeappels/importer/importer.component';
import { TabledebordComponent } from './views/tabledebord/tabledebord.component';
import { BilannouveauComponent } from './views/listeappels/bilannouveau/bilannouveau.component';
import { BilandiscussionComponent } from './views/listeappels/bilandiscussion/bilandiscussion.component';
import { BilanrelanceComponent } from './views/listeappels/bilanrelance/bilanrelance.component';
import { FacturationComponent } from './views/listeappels/facturation/facturation.component';
import { ComptabiliteComponent } from './views/listeappels/comptabilite/comptabilite.component';
import { ComptabiliteatraiterComponent } from './views/listeappels/comptabiliteatraiter/comptabiliteatraiter.component';
import { Tableaudebord2Component } from './views/tableaudebord2/tableaudebord2.component';


const appRoutes: Routes = [
  { path: 'listeappels', component: ListeappelsComponent, canActivate: [AuthguardService]},
  { path: 'bilan', component: TabledebordComponent, canActivate: [AuthguardService]},
  { path: 'importer', component: ImporterComponent, canActivate: [AuthguardService]},
  { path: 'jamaisrelance', component: JamaisrelanceComponent, canActivate: [AuthguardService]},
  { path: 'premierecnx/:id', component: PremiereconxComponent},
  { path: 'login', component: LoginComponent },
  { path: 'listeutilisateur', component: LsteutilisateurComponent, canActivate: [AuthguardService]},
  { path: 'appel/:id', component: AppelComponent, canActivate: [AuthguardService]},
  { path: 'utilisateur/:id', component: UtilisateurComponent, canActivate: [AuthguardService]},
  { path: 'ajoutappel/:id', component: AjoutappelComponent, canActivate: [AuthguardService]},
  { path: 'relance/:id', component: RelanceComponent, canActivate: [AuthguardService]},
  { path: 'bilannouveaux', component: BilannouveauComponent, canActivate: [AuthguardService]},
  { path: 'bilandiscussion', component: BilandiscussionComponent, canActivate: [AuthguardService]},
  { path: 'bilanrelance', component: BilanrelanceComponent, canActivate: [AuthguardService]},
  { path: 'facturation', component: FacturationComponent, canActivate: [AuthguardService]},
  { path: 'comptabilite', component: ComptabiliteComponent, canActivate: [AuthguardService]},
  { path: 'comptabiliteatraiter', component: ComptabiliteatraiterComponent, canActivate: [AuthguardService]},
  { path: 'tableaudebord', component: Tableaudebord2Component, canActivate: [AuthguardService]},
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: LoginComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AppelComponent,
    ListeappelsComponent,
    LsteutilisateurComponent,
    UtilisateurComponent,
    LoginComponent,
    PremiereconxComponent,
    AjoutappelComponent,
    RelanceComponent,
    JamaisrelanceComponent,
    ImporterComponent,
    TabledebordComponent,
    BilannouveauComponent,
    BilandiscussionComponent,
    BilanrelanceComponent,
    FacturationComponent,
    ComptabiliteComponent,
    ComptabiliteatraiterComponent,
    Tableaudebord2Component,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    ModalModule.forRoot(),
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes,
      {enableTracing: true })
  ],
  providers: [
    DatePipe,
    AuthguardService,
    ConfigService,
    UtilisateurService,
    AppelService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
