import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../service/config.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {UtilisateurService} from '../../service/utilisateur.service';

@Component({
  selector: 'app-premiereconx',
  templateUrl: './premiereconx.component.html',
  styleUrls: ['./premiereconx.component.css']
})
export class PremiereconxComponent implements OnInit {
  old;
  new;
  mdp;
  valide = 0;
  valide1 = 0;
  Idusers;

  constructor(private configurationService: ConfigService,
              private usersService: UtilisateurService,
              private router: Router,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.configurationService.varauthPass = false;
    this.Idusers = this.configurationService.varIdUtilisateur;
  }

  verifi(f) {
    console.log(f.value);
    if (this.old !== this.configurationService.sauvmotdepasse) {
      this.valide = 1;
    } else {
      this.valide = 0;
      this.verifi1(f);
    }
  }

  verifi1(f) {
    if (this.new !== this.mdp) {
      this.valide1 = 1;
    } else {
      this.valide1 = 0;
      this.usersService.updateUserMDP(f.value).subscribe(md => {
          localStorage.setItem('pass', '1');
          localStorage.setItem('id', this.configurationService.varIdUtilisateur);
          localStorage.setItem('login', this.configurationService.varnomutilisateur);
          this.router.navigate(['/tableaudebord']);
          this.toastr.info(' BIENVENUE ' + this.configurationService.varnomutilisateur);
          this.configurationService.varconnecter = true;
          this.configurationService.varauthPass = true;


          localStorage.setItem('admin', this.configurationService.varAdmin);
          localStorage.setItem('Profile', this.configurationService.Profile);

        }, () => {
          localStorage.setItem('pass', '0');
          this.toastr.error(' La modification du mot de passe a échouée!');
        }
      );
    }
  }
}
