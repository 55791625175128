import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  varTitre: string;
  // urlg = 'http://172.16.2.16:8020';
 //  urlg = 'http://127.0.0.1:8080';
 // urlg = 'http://apicrystal.innovation-ci.com';
 // urlg = 'http://webapi.clinique-procrea.com';
  urlg = 'http://webapi.clinique-procrea.com';
  varnomPhoto: string ;
  varAdmin ;
  varconnecter = false;
  varauthPass = false;
  sauvmotdepasse;
  varIdUtilisateur;
  varnomutilisateur;
  recherche = 0;
  Profile;
  constructor() { }
}
