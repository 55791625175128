import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UtilisateurService} from '../../service/utilisateur.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService, ModalDirective} from 'ngx-bootstrap/modal';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Utilisateur} from '../../modele/utilisateur';
import {ConfigService} from '../../service/config.service';

@Component({
  selector: 'app-lsteutilisateur',
  templateUrl: './lsteutilisateur.component.html',
  styleUrls: ['./lsteutilisateur.component.css']
})
export class LsteutilisateurComponent implements OnInit {


  listutilisateur: any ;
  varRech: string;
  varAff: number = null;
  fermeModal;

  varId;
  modalRef: BsModalRef;
  message: string;

  displayedColumns = ['index', 'Login', 'Profile', 'Admin', 'actmod', 'actsup'];

  dataSource = new MatTableDataSource<Utilisateur>(this.listutilisateur);

  @ViewChild('primaryModal', {static: false}) public primaryModal: ModalDirective;
  @ViewChild('successModal', {static: false}) public successModal: ModalDirective;
  @ViewChild('warningModal', {static: false}) public warningModal: ModalDirective;
  @ViewChild('dangerModal', {static: false}) public dangerModal: ModalDirective;
  @ViewChild('infoModal', {static: false}) public infoModal: ModalDirective;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  constructor(private utilisateurService: UtilisateurService,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public  configurationService: ConfigService) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.listeutilisateur();
    console.log('ssssssssss' + this.configurationService.varAdmin) ;
  }

  // Gestion de modal
  fermemodal() {
    this.fermeModal = 0;
  }

  delete(id: number) {
    this.fermeModal = 1;
    this.varId = id;
  }
  // Fin Gestion de modal

  listeutilisateur() {
    this.utilisateurService.readUtilisateur().subscribe(utilisateurs => {
      this.listutilisateur = utilisateurs;
      this.dataSource.data = utilisateurs;
    });
  }


  recherche() {
    if (!this.varRech) {
      this.listeutilisateur();
    } else {
      this.utilisateurService.recherchenumeroutilemulticritere(this.varRech).subscribe(utilisateurs => {
        this.listutilisateur = utilisateurs;
        this.dataSource.data = utilisateurs;
      });
    }
  }

  deletevrai() {
    this.utilisateurService.deleteUtilisateur(this.varId).subscribe(rep => {
        this.toastr.success('Suppression terminée avec succès !');
        this.listeutilisateur();
      } , () => {
        this.toastr.error('Suppression échouée !');
      }
    );
  }

}
