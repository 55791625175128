import { Injectable } from '@angular/core';
import {ConfigService} from './config.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppelService {

  url = this.configService.urlg;

  constructor(private httpClient: HttpClient,
              private configService: ConfigService) {}

  readAppel(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url + '/api/read/listeappel.php');
  }

  readAppelimporte(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url + '/api/read/listeappelimporte.php');
  }

  readAppellamisrelance(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url + '/api/read/listeappeljamaisrelance.php');
  }

  readAppelproforma(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url + '/api/read/listeappelproforma.php');
  }

  readAppelcomptabilité(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url + '/api/read/listeappelcomptabilite.php');
  }

  readAppelcomptabilitéatraiter(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url + '/api/read/listeappelcomptabiliteatraiter.php');
  }

  readoneAppel(id): Observable<any> {
    return this.httpClient.get<any>(this.url + '/api/read/oneappel.php?id=' + id);
  }

  doublon(id): Observable<any> {
    return this.httpClient.get<any>(this.url + '/api/read/doublonappel.php?id=' + id);
  }

  creatAppel(appel: any): Observable<any> {
    return this.httpClient.post<any>(this.url + '/api/create/appel.php', appel);
  }

  creatAppel2(appel: any): Observable<any> {
    return this.httpClient.post<any>(this.url + '/api/create/appel2.php', appel);
  }

  updateAppel(appel: any): Observable<any>  {
    return this.httpClient.put<any>(this.url + '/api/update/appel.php', appel);
  }

  updaterelanceProstect(id: any): Observable<any>  {
    return this.httpClient.put<any>(this.url + '/api/update/appelrelance.php', id);
  }

  updateproformaProstect(id: any): Observable<any>  {
    return this.httpClient.put<any>(this.url + '/api/update/appelproforma.php', id);
  }

  updatecomptaProstect(id: any): Observable<any>  {
    return this.httpClient.put<any>(this.url + '/api/update/appelcompta.php', id);
  }

  updateNiveauinteret(id: any): Observable<any>  {
    return this.httpClient.put<any>(this.url + '/api/update/appelmsjinteret.php', id);
  }

  deleteAppel(id: number) {
    return this.httpClient.delete<any>(this.url + '/api/delete/appel.php?id=' + id);
  }

  readConversation(id): Observable<any> {
    return this.httpClient.get<any>(this.url + '/api/read/conversation.php?id=' + id);
  }

  readRelance(id): Observable<any> {
    return this.httpClient.get<any>(this.url + '/api/read/relance.php?id=' + id);
  }

  readRelancecpt(id): Observable<any> {
    return this.httpClient.get<any>(this.url + '/api/read/relancecpt.php?id=' + id);
  }

  creatConversation(appel: any): Observable<any> {
    return this.httpClient.post<any>(this.url + '/api/create/conversation.php', appel);
  }

  recherchNouveau(id?: string): Observable<any> {
    return this.httpClient.get<any>(this.url + '/api/read/RechercheNouveau.php?id=' + id);
  }

  readdiscussionConversation(id?: string): Observable<any> {
    return this.httpClient.get<any>(this.url + '/api/read/readdiscussionConversation.php?id=' + id);
  }

  readrelanceConversation(id?: string): Observable<any> {
    return this.httpClient.get<any>(this.url + '/api/read/readrelanceConversation.php?id=' + id);
  }

  readtableaudebord(): Observable<any> {
    return this.httpClient.get<any>(this.url + '/api/read/tableaudebord.php');
  }

}
