import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DatePipe, Location} from '@angular/common';
import {AppelService} from '../../../service/appel.service';
import {ConfigService} from '../../../service/config.service';
import {Conversation} from '../../../modele/conversation';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-relance',
  templateUrl: './relance.component.html',
  styleUrls: ['./relance.component.css']
})
export class RelanceComponent implements OnInit { listeConversation;
  myDate;
  myTime;
  idProspect: number;
  appel;
  cpt;
  NbreRelance = 0;
  NiveauInters;
  DateDern;


  conversation: Conversation = {Conver_Date: null, Conver_Echange: null, Conver_heure: null, Conver_Id: null,
    Conver_Relance: null, Conver_Type: null, Conver_AppelId: null, Conver_UserId: null, Conver_Interresse: null}

  constructor(private datePipe: DatePipe,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private location: Location,
              private appelService: AppelService,
              private configService: ConfigService) { }


  ngOnInit() {
    this.configService.recherche = 1;
    this.loadConversation();

  }

  loadConversation() {
    this.configService.recherche = 1;
    const id = this.route.snapshot.paramMap.get('id');
    if (id !== '0') {
      this.appelService.readoneAppel(id).subscribe(a => this.appel = a);
      this.idProspect = Number(id);

      this.appelService.readRelance(id).subscribe(conversation => {
        this.listeConversation = conversation;
        console.log('tototototototo', this.listeConversation);
      });

      this.appelService.readRelancecpt(id).subscribe(cpt => {
        this.cpt = cpt;
        this.NbreRelance = Number(this.cpt.cpt) + 1;
        this.configService.recherche = 0;
      });

      this.conversation = {Conver_Date: null, Conver_Echange: null, Conver_heure: null, Conver_Id: null,
        Conver_Relance: null, Conver_Type: null, Conver_AppelId: null, Conver_UserId: null, Conver_Interresse: null}
      this.chargeDateTime();
      this.conversation.Conver_UserId = this.configService.varIdUtilisateur;
      this.conversation.Conver_AppelId = this.idProspect;

    } else {
      alert('Erreur de reccuperation du prospect!');

    }
  }

  chargeDateTime() {
    this.myDate = new Date();
    this.myTime = this.myDate.getTime();
    this.conversation.Conver_Date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.conversation.Conver_heure = this.datePipe.transform(this.myTime, 'hh:mm');
    this.conversation.Conver_Relance = 2;
  }

  submit(form) {

    console.log(form.value);
    this.configService.recherche = 1;

    this.idProspect = this.conversation.Conver_AppelId ;
    this.NiveauInters = this.conversation.Conver_Interresse ;
    this.DateDern = this.conversation.Conver_Date;

    this.appelService.creatConversation(form.value).subscribe((conversation: any)  => {
      if ( conversation == 1 ) {
        this.toastr.success('Enregistrement terminé avec succes !');
        this.updateRelanceProstect();
        this.appelService.readoneAppel(this.idProspect).subscribe(a => this.appel = a);
        this.loadConversation();
        this.updateNiveauinteret();
      } else {
        this.toastr.error('L\'enrgistrement à échoué !');
      }
    },() => {
      this.toastr.error('L\'enrgistrement à échoué !');
    });

  }

  loadOneAppel() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id !== '0') {
      this.appelService.readoneAppel(id).subscribe(a => this.appel = a);
    }
  }

  updateRelanceProstect() {
    const myObj = {
      id: this.idProspect
    };
    const myObjStr = JSON.stringify(myObj);
    console.log('eeeeeeeee ' + myObjStr);

    this.appelService.updaterelanceProstect(myObjStr).subscribe(a => this.appel = a);
  }

  updateNiveauinteret() {
    const myObj = {
      id: this.idProspect,
      APPEL_NiveauInters: this.NiveauInters,
      APPEL_DATEDER: this.DateDern
    };
    const myObjStr = JSON.stringify(myObj);
    console.log('eeeeeeeee ' + myObjStr);
    this.appelService.updateNiveauinteret(myObjStr).subscribe(a => {
      this.appel = a;
      this.toastr.success('Interêt mise à jour !');
    },() => {
      this.toastr.error('Interêt n\'a pas été mis à jour !');
    });
  }


}
