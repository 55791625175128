import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ToastrService} from 'ngx-toastr';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {AppelService} from '../../../service/appel.service';
import {Appel} from '../../../modele/appel';
import {ConfigService} from '../../../service/config.service';

@Component({
  selector: 'app-jamaisrelance',
  templateUrl: './jamaisrelance.component.html',
  styleUrls: ['./jamaisrelance.component.css']
})
export class JamaisrelanceComponent implements OnInit {

  listeAppel;
  displayedColumns: string[] = ['NomPrenoms', 'Telephone', 'User', 'Par', 'Interet', 'Relancer', 'Modif', 'Sup', 'Ajout', 'Relance'];
  dataSource = new MatTableDataSource<Appel>(this.listeAppel);
  fermeModal = 0;
  varId;

  constructor(private appelService: AppelService,
              private toastr: ToastrService,
              private configurationService: ConfigService) { }

  @ViewChild('primaryModal', {static: false}) public primaryModal: ModalDirective;
  @ViewChild('successModal', {static: false}) public successModal: ModalDirective;
  @ViewChild('warningModal', {static: false}) public warningModal: ModalDirective;
  @ViewChild('dangerModal', {static: false}) public dangerModal: ModalDirective;
  @ViewChild('infoModal', {static: false}) public infoModal: ModalDirective;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.configurationService.recherche = 0;
    this.loadAppel();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  loadAppel() {
    this.configurationService.recherche = 1;
    this.appelService.readAppellamisrelance().subscribe(appel => {
      this.listeAppel = appel;
      this.dataSource.data = appel;
      this.configurationService.recherche = 0;
    }, () =>{
      this.configurationService.recherche = 0;
    });
  }

  fermemodal() {
    this.fermeModal = 0;
  }

  delete(id: number) {
    this.fermeModal = 1;
    this.varId = id;
  }

  deletevrai() {
    console.log('totototototot', this.varId);
    this.appelService.deleteAppel(this.varId).subscribe(rep => {
      if (rep === 0) {
        this.toastr.error('Suppression échouée !');
      } else {
        this.toastr.success('Suppression terminée avec succès !');
        this.loadAppel();
      }
    }, () => {
      this.toastr.error('Suppression échouée !');
    });
  }


}
