import {Component, OnInit, ViewChild} from '@angular/core';
// *********
import * as XLSX from 'xlsx';
import {Error} from 'tslint/lib/error';
import {ToastrService} from 'ngx-toastr';
import {AppelService} from '../../../service/appel.service';
import {MatTableDataSource} from '@angular/material/table';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Appel} from '../../../modele/appel';
import {ConfigService} from '../../../service/config.service';
type AOA = any[][];
// *********


@Component({
  selector: 'app-importer',
  templateUrl: './importer.component.html',
  styleUrls: ['./importer.component.css']
})
export class ImporterComponent implements OnInit {
  dataFac: AOA = [];
  fac = 0;
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName = 'SheetJS.xlsx';


  listeAppel;
  displayedColumns: string[] = ['NomPrenoms', 'Telephone', 'User', 'Par', 'Interet', 'Relancer', 'Modif', 'Sup', 'Ajout', 'Relance'];
  dataSource = new MatTableDataSource<Appel>(this.listeAppel);
  fermeModal = 0;
  varId;


  constructor(private toastr: ToastrService,
              private appelService: AppelService,
              private configurationService: ConfigService) { }

  @ViewChild('primaryModal', {static: false}) public primaryModal: ModalDirective;
  @ViewChild('successModal', {static: false}) public successModal: ModalDirective;
  @ViewChild('warningModal', {static: false}) public warningModal: ModalDirective;
  @ViewChild('dangerModal', {static: false}) public dangerModal: ModalDirective;
  @ViewChild('infoModal', {static: false}) public infoModal: ModalDirective;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.configurationService.recherche = 0;
    this.loadAppel();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // ********************************* Faculte
  onFileChangeFac(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.dataFac = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1}));
      this.fac = 1;
    };
    reader.readAsBinaryString(target.files[0]);
  }
  EnregistrerFac() {
    console.log(this.dataFac);
    this.appelService.creatAppel2(this.dataFac).subscribe((fac: any)  => {
      this.toastr.success('Enregistrement terminé avec succes !');
      this.dataFac = [];
      this.fac = 0;
      this.loadAppel();
    },() => {
      this.toastr.error('L\'enrgistrement à échoué !');
      this.dataFac = [];
      this.fac = 0;
    });
  }
  // *********************************

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  loadAppel() {
    this.configurationService.recherche = 1;
    this.appelService.readAppelimporte().subscribe(appel => {
      this.listeAppel = appel;
      this.dataSource.data = appel;
      this.configurationService.recherche = 0;
    }, () => {
      this.configurationService.recherche = 0;
    });
  }

  fermemodal() {
    this.fermeModal = 0;
  }

  delete(id: number) {
    this.fermeModal = 1;
    this.varId = id;
  }

  deletevrai() {
    console.log('totototototot', this.varId);
    this.appelService.deleteAppel(this.varId).subscribe(rep => {
      if (rep === 0) {
        this.toastr.error('Suppression échouée !');
      } else {
        this.toastr.success('Suppression terminée avec succès !');
        this.loadAppel();
      }
    }, () => {
      this.toastr.error('Suppression échouée !');
    });
  }

}
