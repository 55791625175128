import {ConfigService} from './config.service';
import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {
  key = 'pass';
  myItem = '0';

  constructor(private configService: ConfigService,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {

    this.myItem = localStorage.getItem('pass');
    if (this.myItem == null) {
      // alert('tititi' + this.myItem);
      this.configService.varauthPass = false;
      this.router.navigate(['/login']);
    }

    if (this.myItem == '0') {
      // alert('tatata' + this.myItem);
      this.configService.varauthPass = false;
      this.router.navigate(['/login']);
    }

    if (this.myItem == '1') {
      // alert('tototot' + this.myItem);
      this.configService.varIdUtilisateur = localStorage.getItem('id');
      this.configService.varnomutilisateur = localStorage.getItem('login');
      this.configService.varconnecter = true;
      this.configService.varauthPass = true;
      this.configService.varAdmin = localStorage.getItem('admin');
      this.configService.Profile = localStorage.getItem('Profile');

    }

    return this.configService.varconnecter ;
  }

}
/*
import { Component } from '@angular/core';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  name = 'Angular';;
  key: string = 'login';
  myItem: string;
  constructor() {
    localStorage.setItem(this.key, 'Value');
    this.myItem = localStorage.getItem(this.key);
    localStorage.setItem(this.key, 'New Value toto');
    this.myItem = localStorage.getItem(this.key);
    console.log(localStorage);
    localStorage.removeItem(this.key);
    this.myItem = localStorage.getItem(this.key);
    localStorage.clear();
    this.myItem = localStorage.getItem(this.key);
  }


}*/
