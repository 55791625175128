import { Component, OnInit } from '@angular/core';
import {AppelService} from '../../service/appel.service';

@Component({
  selector: 'app-tableaudebord2',
  templateUrl: './tableaudebord2.component.html',
  styleUrls: ['./tableaudebord2.component.css']
})
export class Tableaudebord2Component implements OnInit {
rf;
rt;
rp;
rw;
rs;

ret1;
  Rep;  // = {TOTAL_PROSPECT: null, TOTAL_INTERET: null, TOTAL_PROFORMA: null, TOTAL_ENGAGE: null}

  constructor(private appelService: AppelService) { }

  ngOnInit() {
    this.chargetableau();
  }

  chargetableau() {
    this.appelService.readtableaudebord().subscribe(ret => {
      this.Rep = ret;
      this.Rep.RATIO_FACEBOOK = parseInt(ret.RATIO_FACEBOOK, 10);
      this.Rep.RATIO_PHYSIQUEMENT = parseInt(ret.RATIO_PHYSIQUEMENT, 10);
      this.Rep.RATIO_TELEPHONE = parseInt(ret.RATIO_TELEPHONE, 10);
      this.Rep.RATIO_WHATSAPP = parseInt(ret.RATIO_WHATSAPP, 10);
      this.Rep.RATIO_SITE = parseInt(ret.RATIO_SITE, 10);

      if (this.Rep.RATIO_FACEBOOK >= 1 && this.Rep.RATIO_FACEBOOK <= 10 ) {
        this.rf = 10;
      }
      if (this.Rep.RATIO_FACEBOOK > 10 && this.Rep.RATIO_FACEBOOK <= 20 ) {
        this.rf = 20;
      }
      if (this.Rep.RATIO_FACEBOOK > 20 && this.Rep.RATIO_FACEBOOK <= 30 ) {
        this.rf = 30;
      }
      if (this.Rep.RATIO_FACEBOOK > 30 && this.Rep.RATIO_FACEBOOK <= 40 ) {
        this.rf = 40;
      }
      if (this.Rep.RATIO_FACEBOOK > 40 && this.Rep.RATIO_FACEBOOK <= 50 ) {
        this.rf = 50;
      }
      if (this.Rep.RATIO_FACEBOOK > 50 && this.Rep.RATIO_FACEBOOK <= 60 ) {
        this.rf = 60;
      }
      if (this.Rep.RATIO_FACEBOOK > 60 && this.Rep.RATIO_FACEBOOK <= 70 ) {
        this.rf = 70;
      }
      if (this.Rep.RATIO_FACEBOOK > 70 && this.Rep.RATIO_FACEBOOK <= 80 ) {
        this.rf = 80;
      }
      if (this.Rep.RATIO_FACEBOOK > 80 && this.Rep.RATIO_FACEBOOK <= 90 ) {
        this.rf = 90;
      }
      if (this.Rep.RATIO_FACEBOOK > 90 && this.Rep.RATIO_FACEBOOK <= 100 ) {
        this.rf = 100;
      }

      if (this.Rep.RATIO_PHYSIQUEMENT >= 1 && this.Rep.RATIO_PHYSIQUEMENT <= 10 ) {
        this.rp = 10;
      }
      if (this.Rep.RATIO_PHYSIQUEMENT > 10 && this.Rep.RATIO_PHYSIQUEMENT <= 20 ) {
        this.rp = 20;
      }
      if (this.Rep.RATIO_PHYSIQUEMENT > 20 && this.Rep.RATIO_PHYSIQUEMENT <= 30 ) {
        this.rp = 30;
      }
      if (this.Rep.RATIO_PHYSIQUEMENT > 30 && this.Rep.RATIO_PHYSIQUEMENT <= 40 ) {
        this.rp = 40;
      }
      if (this.Rep.RATIO_PHYSIQUEMENT > 40 && this.Rep.RATIO_PHYSIQUEMENT <= 50 ) {
        this.rp = 50;
      }
      if (this.Rep.RATIO_PHYSIQUEMENT > 50 && this.Rep.RATIO_PHYSIQUEMENT <= 60 ) {
        this.rp = 60;
      }
      if (this.Rep.RATIO_PHYSIQUEMENT > 60 && this.Rep.RATIO_PHYSIQUEMENT <= 70 ) {
        this.rp = 70;
      }
      if (this.Rep.RATIO_PHYSIQUEMENT > 70 && this.Rep.RATIO_PHYSIQUEMENT <= 80 ) {
        this.rp = 80;
      }
      if (this.Rep.RATIO_PHYSIQUEMENT > 80 && this.Rep.RATIO_PHYSIQUEMENT <= 90 ) {
        this.rp = 90;
      }
      if (this.Rep.RATIO_PHYSIQUEMENT > 90 && this.Rep.RATIO_PHYSIQUEMENT <= 100 ) {
        this.rp = 100;
      }

      if (this.Rep.RATIO_TELEPHONE >= 1 && this.Rep.RATIO_TELEPHONE <= 10 ) {
        this.rt = 10;
      }
      if (this.Rep.RATIO_TELEPHONE > 10 && this.Rep.RATIO_TELEPHONE <= 20 ) {
        this.rt = 20;
      }
      if (this.Rep.RATIO_TELEPHONE > 20 && this.Rep.RATIO_TELEPHONE <= 30 ) {
        this.rt = 30;
      }
      if (this.Rep.RATIO_TELEPHONE > 30 && this.Rep.RATIO_TELEPHONE <= 40 ) {
        this.rt = 40;
      }
      if (this.Rep.RATIO_TELEPHONE > 40 && this.Rep.RATIO_TELEPHONE <= 50 ) {
        this.rt = 50;
      }
      if (this.Rep.RATIO_TELEPHONE > 50 && this.Rep.RATIO_TELEPHONE <= 60 ) {
        this.rt = 60;
      }
      if (this.Rep.RATIO_TELEPHONE > 60 && this.Rep.RATIO_TELEPHONE <= 70 ) {
        this.rt = 70;
      }
      if (this.Rep.RATIO_TELEPHONE > 70 && this.Rep.RATIO_TELEPHONE <= 80 ) {
        this.rt = 80;
      }
      if (this.Rep.RATIO_TELEPHONE > 80 && this.Rep.RATIO_TELEPHONE <= 90 ) {
        this.rt = 90;
      }
      if (this.Rep.RATIO_TELEPHONE > 90 && this.Rep.RATIO_TELEPHONE <= 100 ) {
        this.rt = 100;
      }

      if (this.Rep.RATIO_WHATSAPP >= 1 && this.Rep.RATIO_WHATSAPP <= 10 ) {
        this.rw = 10;
      }
      if (this.Rep.RATIO_WHATSAPP > 10 && this.Rep.RATIO_WHATSAPP <= 20 ) {
        this.rw = 20;
      }
      if (this.Rep.RATIO_WHATSAPP > 20 && this.Rep.RATIO_WHATSAPP <= 30 ) {
        this.rw = 30;
      }
      if (this.Rep.RATIO_WHATSAPP > 30 && this.Rep.RATIO_WHATSAPP <= 40 ) {
        this.rw = 40;
      }
      if (this.Rep.RATIO_WHATSAPP > 40 && this.Rep.RATIO_WHATSAPP <= 50 ) {
        this.rw = 50;
      }
      if (this.Rep.RATIO_WHATSAPP > 50 && this.Rep.RATIO_WHATSAPP <= 60 ) {
        this.rw = 60;
      }
      if (this.Rep.RATIO_WHATSAPP > 60 && this.Rep.RATIO_WHATSAPP <= 70 ) {
        this.rw = 70;
      }
      if (this.Rep.RATIO_WHATSAPP > 70 && this.Rep.RATIO_WHATSAPP <= 80 ) {
        this.rw = 80;
      }
      if (this.Rep.RATIO_WHATSAPP > 80 && this.Rep.RATIO_WHATSAPP <= 90 ) {
        this.rw = 90;
      }
      if (this.Rep.RATIO_WHATSAPP > 90 && this.Rep.RATIO_WHATSAPP <= 100 ) {
        this.rw = 100;
      }

      if (this.Rep.RATIO_SITE >= 1 && this.Rep.RATIO_SITE <= 10 ) {
        this.rs = 10;
      }
      if (this.Rep.RATIO_SITE > 10 && this.Rep.RATIO_SITE <= 20 ) {
        this.rs = 20;
      }
      if (this.Rep.RATIO_SITE > 20 && this.Rep.RATIO_SITE <= 30 ) {
        this.rs = 30;
      }
      if (this.Rep.RATIO_SITE > 30 && this.Rep.RATIO_SITE <= 40 ) {
        this.rs = 40;
      }
      if (this.Rep.RATIO_SITE > 40 && this.Rep.RATIO_SITE <= 50 ) {
        this.rs = 50;
      }
      if (this.Rep.RATIO_SITE > 50 && this.Rep.RATIO_SITE <= 60 ) {
        this.rs = 60;
      }
      if (this.Rep.RATIO_SITE > 60 && this.Rep.RATIO_SITE <= 70 ) {
        this.rs = 70;
      }
      if (this.Rep.RATIO_SITE > 70 && this.Rep.RATIO_SITE <= 80 ) {
        this.rs = 80;
      }
      if (this.Rep.RATIO_SITE > 80 && this.Rep.RATIO_SITE <= 90 ) {
        this.rs = 90;
      }
      if (this.Rep.RATIO_SITE > 90 && this.Rep.RATIO_SITE <= 100 ) {
        this.rs = 100;
      }

    });
  }
}
