import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {UtilisateurService} from '../../../service/utilisateur.service';
import {ConfigService} from '../../../service/config.service';
import {Appel} from '../../../modele/appel';
import {AppelService} from '../../../service/appel.service';

@Component({
  selector: 'app-bilannouveau',
  templateUrl: './bilannouveau.component.html',
  styleUrls: ['./bilannouveau.component.css']
})
export class BilannouveauComponent implements OnInit {
  listeUtilisateur;
  listappels;
  datedeb;
  datefin;
  utili;
  niveauInteret;
  canal;
  total;

  displayedColumns: string[] = ['Date', 'NomPrenoms', 'Telephone1', 'Telephone2', 'Email', 'Par', 'Interet', 'Relancer'];
  dataSource = new MatTableDataSource<Appel>(this.listappels);

  constructor(private utilisateurService: UtilisateurService,
              private appelService: AppelService,
              private configService: ConfigService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.chargeUtilisateur();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return  data.APPEL_NiveauInters.toString() === filter || data.APPEL_Premiere.toString() === filter;
    };
    this.total = this.dataSource.data.length;
  }

  chargeUtilisateur() {
    this.utilisateurService.readUtilisateur().subscribe(user => {
      this.listeUtilisateur = user;
    });
  }

  submit(form) {
    this.configService.recherche = 1;
    this.appelService.recherchNouveau(JSON.stringify(form.value)).subscribe(list => {
      if (list == null) {
        this.total = this.dataSource.data.length;
        this.configService.recherche = 0;
        return;
      } else {
        this.listappels = list;
        this.dataSource.data = list;
        this.configService.recherche = 0;
        this.total = this.dataSource.data.length;
      }

    }, () => {
      this.total = this.dataSource.data.length;
      this.configService.recherche = 0;
      return;

    });
    // this.getTotalCost();
  }

  applyFilter() {
   //  alert(this.niveauInteret);
    this.canal = '';
    this.dataSource.filter = this.niveauInteret;
    this.total = this.dataSource.filteredData.length;
   // this.getTotalCost();
  }

  applyFilter2() {
    // alert(this.canal);
    this.niveauInteret = '';
    this.dataSource.filter = this.canal;
    this.total = this.dataSource.filteredData.length;

  }

  /** Gets the total cost of all transactions.
  getTotalCost() {
    return this.listappels.map(t => t.ART_PRIX).reduce((acc, value) => acc + value, 0);
  }*/

}
