import {Component, OnInit, ViewChild} from '@angular/core';
import {Appel} from '../../../modele/appel';
import {DatePipe, Location} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../../../service/config.service';
import {AppelService} from '../../../service/appel.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {interpretStatements} from '@angular/compiler/src/output/output_interpreter';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-appel',
  templateUrl: './appel.component.html',
  styleUrls: ['./appel.component.css']
})
export class AppelComponent implements OnInit {
  myDate = new Date();
  myTime;
  listeAppel;
  displayedColumns: string[] = ['Date', 'Heure', 'NomPrenoms', 'Telephone', 'User', 'Conv.', 'Details', 'Ajout'];
  dataSource = new MatTableDataSource<Appel>(this.listeAppel);
  verifdouble = {cpt: null}
  az;

  constructor(private datePipe: DatePipe,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private location: Location,
              private appelService: AppelService,
              private configService: ConfigService) { }

  appel: Appel = {APPEL_CANDIDAT1: null, APPEL_CANDIDAT2: null, APPEL_DATE: null, APPEL_DATENAIS: null, APPEL_EMAIL: null, APPEL_HEURE: null,
    APPEL_ID: null, APPEL_IDUSER: null, APPEL_NOMPRENOMS: null, APPEL_PAYS: null, APPEL_PROFESSION: null, APPEL_PSEUDOSKYPE: null,
    APPEL_RESUME: null, APPEL_SEXE: null, APPEL_SITUMATRI: null, APPEL_TEL1: null, APPEL_TEL2: null, APPEL_VILLE: null, APPEL_Convertis: null,
    APPEL_NiveauInters: null, APPEL_Premiere: null, APPEL_Relance: null, APPEL_Type: null }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.configService.recherche = 1;
    this.appel.APPEL_SITUMATRI = '';
    this.appel.APPEL_SEXE = '';
    this.appel.APPEL_PAYS = '';
    this.appel.APPEL_CANDIDAT2 = '';
    this.appel.APPEL_CANDIDAT1 = '';
    this.appel.APPEL_Convertis = 0;
    this.appel.APPEL_Premiere = '';
    this.appel.APPEL_Relance = 0;
    this.chargeDateTime();
    this.loadOneAppel();

    this.loadAppel();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.configService.recherche = 0;
  }

  applyFilter(filterValue: string) {
    this.configService.recherche = 1;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.configService.recherche = 0;
  }

  loadAppel() {
    this.appelService.readAppel().subscribe(appel => {
      this.listeAppel = appel;
      this.dataSource.data = appel;
    });
  }


  submit(form) {
    const id = this.route.snapshot.paramMap.get('id');
    if (id !== '0') {
      this.configService.recherche = 1;
      this.appelService.updateAppel(form.value).subscribe((appel: any) => {
        if ( appel === 1 ) {
          this.toastr.success('Modification réussie');
          this.location.back();
        } else {
          this.toastr.error('Modification échouée !');
        }
        this.configService.recherche = 0;
      }, () => {
        this.toastr.error('Modification échouée !');
        this.configService.recherche = 0;
      });
    } else {
      this.configService.recherche = 1;
      this.appelService.doublon(this.appel.APPEL_TEL1).subscribe(a => {
        this.verifdouble = a;
        if (this.verifdouble.cpt != 0) {
          alert('Ce numéro semble exiter dans la base ! ' +  this.appel.APPEL_TEL1);
          this.configService.recherche = 0;
          return;
        } else {
          this.appelService.creatAppel(form.value).subscribe((appel: any)  => {
            if ( appel == 1 ) {
              this.toastr.success('Enregistrement terminé avec succes !');
              this.appel = {APPEL_CANDIDAT1: null, APPEL_CANDIDAT2: null, APPEL_DATE: null, APPEL_DATENAIS: null, APPEL_EMAIL: null, APPEL_HEURE: null,
                APPEL_ID: null, APPEL_IDUSER: null, APPEL_NOMPRENOMS: null, APPEL_PAYS: null, APPEL_PROFESSION: null, APPEL_PSEUDOSKYPE: null,
                APPEL_RESUME: null, APPEL_SEXE: null, APPEL_SITUMATRI: null, APPEL_TEL1: null, APPEL_TEL2: null, APPEL_VILLE: null, APPEL_Convertis: null,
                APPEL_NiveauInters: null, APPEL_Premiere: null, APPEL_Relance: null, APPEL_Type: null }
              this.chargeDateTime();
              this.appel.APPEL_IDUSER = this.configService.varIdUtilisateur;
              this.configService.recherche = 0;

            } else {
              this.toastr.error('L\'enrgistrement à échoué !');
              this.configService.recherche = 0;
            }
          },() => {
            this.toastr.error('L\'enrgistrement à échoué !');
            this.configService.recherche = 0;
          });
        }
      });
    }
  }

  loadOneAppel() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id !== '0') {
      this.appelService.readoneAppel(id).subscribe(a => this.appel = a);
      this.configService.recherche = 0;
    } else {
      this.appel = {APPEL_CANDIDAT1: null, APPEL_CANDIDAT2: null, APPEL_DATE: null, APPEL_DATENAIS: null, APPEL_EMAIL: null, APPEL_HEURE: null,
        APPEL_ID: null, APPEL_IDUSER: null, APPEL_NOMPRENOMS: null, APPEL_PAYS: null, APPEL_PROFESSION: null, APPEL_PSEUDOSKYPE: null,
        APPEL_RESUME: null, APPEL_SEXE: null, APPEL_SITUMATRI: null, APPEL_TEL1: null, APPEL_TEL2: null, APPEL_VILLE: null, APPEL_Convertis: null,
        APPEL_NiveauInters: null, APPEL_Premiere: null, APPEL_Relance: null, APPEL_Type: null }
      this.chargeDateTime();
      this.appel.APPEL_IDUSER = this.configService.varIdUtilisateur;
      this.configService.recherche = 0;
    }
  }

  chargeDateTime() {
    this.myDate = new Date();
    this.myTime = this.myDate.getTime();
    this.appel.APPEL_DATE = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.appel.APPEL_HEURE = this.datePipe.transform(this.myTime, 'hh:mm');
    this.appel.APPEL_Convertis = 1;
    this.appel.APPEL_Relance = 1 ;
    this.appel.APPEL_SEXE = '' ;
  }

  retour() {
    return;
  }

}
