import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {AppelService} from '../../service/appel.service';
import {Appel} from '../../modele/appel';
import {MatSort} from '@angular/material/sort';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from '../../service/config.service';
import {UtilisateurService} from '../../service/utilisateur.service';

@Component({
  selector: 'app-listeappels',
  templateUrl: './listeappels.component.html',
  styleUrls: ['./listeappels.component.css']
})
export class ListeappelsComponent implements OnInit {
  listeAppel;
  displayedColumns: string[] = ['Date', 'NomPrenoms', 'Telephone', 'User', 'Par', 'Interet', 'Relancer', 'DerTraite', 'Modif', 'Sup', 'Ajout', 'Relance'];
  dataSource = new MatTableDataSource<Appel>(this.listeAppel);
  dataSource2 = new MatTableDataSource<Appel>(this.listeAppel);
  fermeModal = 0;
  varId;
  datedeb;
  datefin;
  listeUtilisateur;
  utili;
  niveauInteret;


  constructor(private appelService: AppelService,
              private toastr: ToastrService,
              private configurationService: ConfigService,
              private utilisateurService: UtilisateurService) { }

  @ViewChild('primaryModal', {static: false}) public primaryModal: ModalDirective;
  @ViewChild('successModal', {static: false}) public successModal: ModalDirective;
  @ViewChild('warningModal', {static: false}) public warningModal: ModalDirective;
  @ViewChild('dangerModal', {static: false}) public dangerModal: ModalDirective;
  @ViewChild('infoModal', {static: false}) public infoModal: ModalDirective;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.configurationService.recherche = 0;
    this.chargeUtilisateur();
    this.loadAppel();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

   /* this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return  data.APPEL_NiveauInters.toString() === filter || data.APPEL_IDUSER.toString() === filter;
    };*/

  }
  chargeUtilisateur() {
    this.utilisateurService.readUtilisateur().subscribe(user => {
      this.listeUtilisateur = user;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* applyFilter2() {
   // alert(this.niveauInteret);
    this.dataSource.filter = this.niveauInteret;
  }
  applyFilter3() {
    // alert(this.utili);
    this.dataSource.filter = this.utili;
  }*/

  loadAppel() {
    this.configurationService.recherche = 1;
    this.appelService.readAppel().subscribe(appel => {
      this.listeAppel = appel;
      this.dataSource.data = appel;
      this.configurationService.recherche = 0;
    }, () =>{
      this.configurationService.recherche = 0;
    });
  }

  fermemodal() {
    this.fermeModal = 0;
  }

  delete(id: number) {
    this.fermeModal = 1;
    this.varId = id;
  }

  deletevrai() {
    console.log('totototototot', this.varId);
    this.appelService.deleteAppel(this.varId).subscribe(rep => {
      if (rep === 0) {
        this.toastr.error('Suppression échouée !');
      } else {
        this.toastr.success('Suppression terminée avec succès !');
        this.loadAppel();
      }
    }, () => {
      this.toastr.error('Suppression échouée !');
    });
  }

}
