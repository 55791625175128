import { Component, OnInit } from '@angular/core';
import {Utilisateur} from '../../../modele/utilisateur';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {ConfigService} from '../../../service/config.service';
import {UtilisateurService} from '../../../service/utilisateur.service';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.css']
})
export class UtilisateurComponent implements OnInit {
  varAff ;
  utilisateur: Utilisateur[];
  selectedUtilisateur: Utilisateur  = {UtilisateurId: null, Login: null, Mdp: null, Email: null, Administrateur: null, Photo: null, prmcnx: null, Profile: null};

  constructor(private toastr: ToastrService,
              private route: ActivatedRoute,
              private location: Location,
              private utilisateurService: UtilisateurService,
              public configurationService: ConfigService) { }

  ngOnInit() {
    this.selectedUtilisateur.Mdp = '123';
    this.selectedUtilisateur.prmcnx = 0;
    this.selectedUtilisateur.Email = '';
    this.selectedUtilisateur.Photo = '';
    this.loadOneUtilisateur();
    // alert(this.selectedUtilisateur.Administrateur);

  }

  submit(form) {
    if (this.selectedUtilisateur.UtilisateurId) {
      console.log(form.value);
      this.utilisateurService.updateUtilisateur(form.value).subscribe((utilisateur: any) => {
        this.toastr.success('Modification réussie');
        this.location.back();
      }, () => {
        this.toastr.error('Modification échouée !');
      });
    } else {
      console.log(form.value);
      this.utilisateurService.creatUtilisateur(form.value).subscribe((utilisateur: any)  => {
        this.toastr.success('Enregistrement terminé avec succes !');
        this.selectedUtilisateur =  {UtilisateurId: null, Login: null, Mdp: null, Email: null, Administrateur: null, Photo: null, prmcnx: null, Profile: null};
      },() => {
        this.toastr.error('L\'enrgistrement à échoué !');
      });
    }

  }

  loadOneUtilisateur() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id !== '0') {
      this.utilisateurService.readoneUtilisateur(id).subscribe(a => {
        this.selectedUtilisateur = a;
        this.varAff = this.selectedUtilisateur.Administrateur;
        this.selectedUtilisateur.Mdp = '';
        this.configurationService.varAdmin = this.selectedUtilisateur.Administrateur;
        console.log('eeeeeeeeeee' +  this.configurationService.varAdmin);

      });
    } else {

    }
  }

  vider() {
    this.selectedUtilisateur =  {UtilisateurId: null, Login: null, Mdp: null, Email: null, Administrateur: null, Photo: null, prmcnx: null, Profile: null};
  }

}
