import { Component, OnInit } from '@angular/core';
import {Utilisateur} from '../../modele/utilisateur';
import {ConfigService} from '../../service/config.service';
import {Router} from '@angular/router';
import {UtilisateurService} from '../../service/utilisateur.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  valide = 0;
  utilisateur;
  login;
  mdp;

  selectedUtilisateur: Utilisateur = {UtilisateurId: null, Login: null, Mdp: null, Email: null, Administrateur: null, Photo: null, prmcnx: null, Profile: null};

  constructor(public configurationService: ConfigService,
              private  router: Router,
              private utilisateurService: UtilisateurService,
              private toastr: ToastrService) { }

  ngOnInit() {
    localStorage.setItem('pass', '0');
    this.configurationService.recherche = 0;
  }

  logIn(form) {
    this.configurationService.recherche = 1;
    this.utilisateurService.recherchUtilisateur(JSON.stringify(this.selectedUtilisateur)).subscribe(util => {
        this.utilisateur = util;
        console.log('ggggggggggggg' + this.utilisateur.Administrateur)
        if (this.utilisateur.prmcnx == 0) {
          localStorage.setItem('pass', '0');
          this.configurationService.varAdmin = this.utilisateur.Administrateur;
          this.configurationService.sauvmotdepasse = this.selectedUtilisateur.Mdp;
          this.configurationService.varIdUtilisateur = this.utilisateur.UtilisateurId;
          this.configurationService.varnomutilisateur = this.utilisateur.Login;
          this.configurationService.Profile = this.utilisateur.Profile;
          this.router.navigate(['/premierecnx/' + this.utilisateur.UtilisateurId]);
        } else {
          if (this.utilisateur.prmcnx == 1) {
            localStorage.setItem('pass', '1');
            localStorage.setItem('id', this.utilisateur.UtilisateurId);
            localStorage.setItem('login', this.utilisateur.Login);
            localStorage.setItem('admin', this.utilisateur.Administrateur);
            localStorage.setItem('Profile', this.utilisateur.Profile);

            this.configurationService.varauthPass = true;
            this.configurationService.varconnecter = true;
            this.configurationService.varAdmin = this.utilisateur.Administrateur;
            this.configurationService.varIdUtilisateur = this.utilisateur.UtilisateurId;
            this.configurationService.varnomutilisateur = this.utilisateur.Login;
            this.configurationService.Profile = this.utilisateur.Profile;

            this.router.navigate(['/tableaudebord']);
            this.toastr.info(' BIENVENUE ' + this.utilisateur.Login);

          } else {
            localStorage.setItem('pass', '0');
            this.valide = 1;
            this.toastr.error(' Echec de la connexion !');
            this.configurationService.varauthPass = false;
            this.configurationService.varconnecter = false;
          }
        }

        this.configurationService.recherche = 0;

      }, () => {
        localStorage.setItem('pass', '0');
        this.valide = 1;
        this.toastr.error(' Echec de la connexion !');
        this.configurationService.varauthPass = false;
        this.configurationService.varconnecter = false;
      }
    );
  }

  admin(id) {
    if (id === 1) {
      this.configurationService.varAdmin = 1;
    } else {
      this.configurationService.varAdmin = 0;
    }
  }
}
